.features-main {
  box-shadow: 1px 1px 6px #000;
  margin: 1rem;
  padding: 1rem;
  font-weight: 700;
  background-color: #ffffff;
}

.buyNow {
  width: 90%;
  padding: 1rem;
  font-size: 150%;
}

.contactNow {
  width: 100%;
  padding: 1rem;
  font-size: 150%;
}