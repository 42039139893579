.footerStyle {
  padding: 1rem;
  /* background-color: #343a40; */
  color: #ffffff;
}

.noBorder {
  border: none;
}

dl, ol, ul {
  margin: 0;
}

.copyBrand {
  /* background-color: #40454b; */
  text-align: center;
  color: #ffffff;
  font-weight: 700;
}

#poweredBy {
  color: white;
  text-decoration: none;
}
#poweredBy:hover {
  color: #ff645e;
  transition: 1s ease-out;
}
#fame {
  color: #ff645e;
}