/* img {
  height: 200px;
} */

.imageContainer {
  position: relative;
}

.image {
  height: 200px;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.imageMiddle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 30%;
  text-align: center;
}

.imageContainer:hover .image {
  opacity: 0.1;
}

.imageContainer:hover .imageMiddle {
  opacity: 1;
}

.imageText {
  color: #545e68;
  font-size: 16px;
}

.card {
  border-radius: 0;
  box-shadow: 1px 1px 6px #000;
  font-weight: 700;
}
