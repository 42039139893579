.buyNow {
  width: 90%;
  padding: 1rem;
  margin: 0.5rem;
  font-size: 150%;
}

.priceBox {
  box-shadow: 1px 1px 6px #000;
  border: 1px solid rgba(0,0,0,.125);
  /* background-color: #c0c6ce; */
  /* background-color: rgb(183, 223, 227); */
  background-color: white;
  margin: 0 0 1rem 0;
  padding: 1rem 0 1rem 0;
}

.btn-light {
  /* border: 1px solid #c0c6ce; */
  border: 1px solid rgba(219, 195, 201, 0.5);
}

.fa-heart:hover, .fa-heart-o:hover {
  font-size: 150%;
  transition: 1s ease-out;
}

.image {
  height: 200px;
}

.card {
  border-radius: 0;
  box-shadow: 1px 1px 6px #000;
}

.tag {
  background-color: #b3dfe2;
  padding: 3px 10px 5px 10px;
  margin: 3px;
}

div .tag-space {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
