#toCenter {
  margin-left: auto;
  margin-right: auto;
}

/* .toUppercase {
  font-size: 130%;
  text-transform: uppercase;
}

.premium {
  font-size: 36%;
  padding: 0;
  margin: 0;
} */
